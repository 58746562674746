.SigninWrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
}

.SigninContainer {
    /* position: relative; */
    width: 100%;
    height: 100vh;
    /* background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0)); */
    /* backdrop-filter: blur(20px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SigninTitleContainer {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    margin-bottom: 32px;
}

.SigninCard {
    position: relative;
    padding: 5%;
    width: 70% !important;
    background-color: var(--card);
    border-radius: 0px;
}

.divSubmitButton {
    width: 100% !important;
}

.SigninContainerChildOne {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SigninContainerChildOne h1 {
    color: var(--white);
}

.ArrowBack {
    position: absolute;
    top: 12% !important;
    left: 17%;
    height: auto;
}

.SigninSignup {
    width: 100%;
    color: var(--cardSubTitle);
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center !important;
    font-size: 20px;
}

.SigninSignupTitle {
    width: 100% !important;
    color: var(--cardSubTitle)!important;
    /* margin-top: 24px; */
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    font-weight: bolder !important;
    font-size: 25px;
    margin: 0px;
}

.submitButton {
    border-radius: 0px !important;
    padding: 16px !important;
    width: 100% !important;
    height: 48px !important;
    color: beige !important;
    font-weight: bold !important;
}

.inputUsernameAndPassword {
    margin-bottom: 40px !important;
    width: 100% !important;
}

.css-1bp1ao6 {
    border-radius: 0px !important;
}

@media (max-width:1250px) {
    .ArrowBack {
        top: 30px;
    }
}

@media (max-width:900px) {
    .ArrowBack {
        left: 5%;
    }
}

@media (max-width:700px) {
    .SigninSignup {
        font-size: 17px;
    }

    .SigninSignupTitle {
        font-size: 22px;
    }
}

@media (max-width:599.5px) {
    .ArrowBack {
        left: 5% !important;
    }

    .SigninSignup {
        font-size: 20px;
    }

    .SigninSignupTitle {
        font-size: 25px;
    }
}

@media (max-width:599.5px) {
    .submitButton {
        width: 80% !important;
        display: flex !important;
        align-self: center !;
    }
}