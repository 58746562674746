.cryptoWrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
}

.oneByOne {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cryptoHeaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90% !important;
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: var(--cardSubTitle);
    box-shadow: 10px 10px 10px rgba(30, 30, 30, 0.5);
    border-radius: 0px;
    padding: 12px;
}

.cryptoLogoName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cryptoLogo {
    width: 80px;
    aspect-ratio: 1/1;
    border-radius: 100px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: 4px;
}

.cryptoName {
    font-size: 24px !important;
    margin: 0;
    color: white;
}

.cryptoCurrency {
    margin: 0;
    color: white;
    font-size:20px;
    float: right;
}

.cryptoName {
    font-size: 18px;
    padding-left: 4px;
    font-weight: 400;
}

.cryptoName span {
    text-transform: uppercase;
}

.cryptoBodyContainer {
    display: flex;
    flex-direction: column;

}

.change,
.volume,
.high,
.low {
    margin-bottom: 4px;
    color: white;
    opacity: 0.7;
    font-size: 12px;
    text-transform: uppercase;
}

.negChange {
    padding-top: 4px;
    padding-left: 4px;
    margin: 0;
    color: red;
    font-size: 18px;
}

.posChange {
    padding-top: 4px;
    padding-left: 4px;
    margin: 0;
    color: rgb(3, 214, 3);
    font-size: 18px;

}


.volumeValue {
    padding-top: 16px;
    opacity: 0.8;
    font-size: 16px;
    margin: 0;
    color: white;
    float: right;
}

@media (max-width:1055px) {

    .cryptoName,
    .cryptoCurrency {
        font-size: 17px;
    }

    .cryptoDetailWrapper {
        flex-direction: row;
    }
}

@media (max-width:350px) {
    .cryptoLogo {
        width: 40px;
    }

    .css-1oqqzyl-MuiContainer-root {
        padding: 0px !important;
    }

    .cryptoName,
    .cryptoCurrency {
        font-size: 16px;
    }

    .negChange {
        font-size: 12px;
    }

    .posChange {
        font-size: 12px;
    }

    .volumeValue {
        font-size: 10px;
    }
}

.cryptocurrencyButton{
    background-color: var(--primary) !important;
    margin-top: 16px !important;
    width: 100%;
    color: var(--white) !important;
    text-transform: capitalize !important;
}