/* h2 {
    color: var(--primary);
} */

.emailTo {
    color: var(--cardSubTitle);
    font-weight: bold;
    margin: 8px 0px;

}

.textContact {
    color: var(--cardSubTitle);
    text-align: center;
    margin: 8px 0px;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 0 !important;
}


.css-twia2z-MuiPaper-root-MuiDialog-paper {
    border-radius: 10px !important;
}

.ContactUsTitleContainer {
    color: var(--cardSubTitle);
    margin: 0;
    padding: 16px;
    text-align: center;
    font-weight: bolder;
    font-size: 32px;
}

.ContactUsSubmitButton {
    margin-top: 12px !important;
    width: 100% !important;
    border-radius: 0px !important;
    background-color: var(--button1) !important;
    height: 48px !important;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 32px !important;
}