.homeButton {
    padding-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 60px;
    height: 60px;
    background-image: url('../../assets/images/logo.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.mobileLogo {
    width: 50px;
    height: 50px;
    background-image: url('../../assets/images/logo.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-left: 16px;
}

.css-11b3ww9-MuiPaper-root-MuiAppBar-root,
.css-5poeop {
    background-color: var(--secondary) !important;
    box-shadow: 0px 0px 0px !important;
    padding: 0px 8px 0px 8px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: static !important;
    /* height: 15vh !important; */
}

.hamburgerMenu {
    margin-top: 8px;
    width: 30px;
    height: 30px;
    background-image: url('../../assets/images/hamburgerMenu.png');
    background-repeat: no-repeat;
    background-size: 100%;
}

.signinButton {
    /* width: 84.6px !important; */
    border-color: white !important;
    margin-left: 16px !important;
    border-radius: 0px !important;
    text-transform: capitalize !important;
    box-shadow: 0px 0px 0px !important;
    color: white !important;
}

.signinButton2 {
    /* width: 84.6px !important; */
    border-color: black !important;
    margin-left: 8px !important;
    margin-bottom: 8px !important;
    border-radius: 0px !important;
    text-transform: capitalize !important;
    box-shadow: 0px 0px 0px !important;
    color: white !important;
}

.signupButton {
    background-color: var(--button1) !important;
    margin-Left: 8px !important;
    border-radius: 0px !important;
    text-transform: capitalize !important;
    box-shadow: 0px 0px 0px !important;
    color: white !important;
}