.profileWrapper {
    width: 100%;
    height: 70vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.profileTitleContainer {
    text-align: center;
    margin-bottom: 16px;
    color: var(--button1);
}

.profileTitle {
    font-size: 30px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 0px;
}

.nameInputContainer,
.emailInputContainer,
.walletPassInputContainer {
    margin: 2%;
}

.nameInputContainer p,
.emailInputContainer p,
.walletPassInputContainer p {
    font-size: 16px;
    font-weight: 700;
    opacity: 0.8;
}

.nameInputContainer div,
.emailInputContainer div,
.walletPassInputContainer div {
    width: 50%;
}

.nameInputContainer,
.walletPassInputContainer,
.emailInputContainer {
    display: flex;
    margin-top: 24px;
}

.profileInput {
    font-size: 16px;
    height: 40px;
    width: 95%;
    border-bottom: 1px solid #888;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    text-decoration-color: #FDA736 !important;
    /* border-radius: 10px; */
    padding-left: 12px;
}

.MuiContainer-root .MuiContainer-maxWidthLg .css-1oqqzyl-MuiContainer-root {
    width: 50%;
    border-color: red;
}

.passwordContainer {
    margin-left: 16px;
    display: flex;
    justify-content: space-between;
}

.editButtonContainer {
    width: 40% !important;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.editButton {
    color: var(--white) !important;
    background-color: var(--button1) !important;
    border-radius: -0px !important;
    height: 50px;
    width: 100%;
}

.rightInput {
    margin-left: 16px;
}

@media (max-width:1300px) {
    .profileWrapper {
        height: auto;
    }

    .profileContainer {
        margin-top: 32px;
        margin-bottom: 32px;
    }
}

@media (max-width:400px) {
    .profileTitleContainer {
        margin-bottom: 32px;
    }

    .nameInputContainer,
    .walletPassInputContainer,
    .emailInputContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .nameInputContainer div,
    .emailInputContainer div,
    .walletPassInputContainer div {
        width: 90%;
    }

    .passwordContainer {
        flex-direction: column;
    }

    .profileInput {
        width: 100%;
    }

    .rightInput {
        margin-left: 0px;
    }

    .editButtonContainer {
        margin-top: 16px;
        width: 100% !important;
    }

}